// sidebar layout css
.sidebar-menu {
    box-shadow: 6px 0px 23px rgba(0, 0, 0, 0.11);
    border-radius: 0px;
    .logo-content {
        .logo {
            width: 55px;
        }
    }
}

// menu content css
.menu-content {
    &__item svg {
        width: 28px;
        height: 28px;
    }
    .active {
        .menu-content__item svg {
            fill: white;
        }
    }
}

.light {
    .menu-content {
        &__item svg {
            width: 28px;
            height: 28px;
        }
        .active {
            .menu-content__item svg {
                fill: var(--color-text-primary);
            }
        }
    }
}

// menu mobile css
.nav-menu-mobile {
    position: fixed;
    top: 65px;
    left: 0;
    width: 65%;
    max-width: 350px;
    height: 95%;
    transform: translate3d(-100%, 0, 0);
    text-align: left;
    transition: transform 0.25s ease-in-out;
}

.navbar-mobile-overlay {
    box-shadow: inset 6px 0px 23px rgba(0, 0, 0, 0.11);
}

// extrinsic css
.extrinsic-page,
.extrinsic-history {
    .events .item.active {
        color: $blue-primary-color;
        border: 1px solid $blue-primary-color;
    }
}

.navbar {
    min-height: 2.5rem;
}

.staking-table {
    max-width: 1514px;
}