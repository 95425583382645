.ag-root-wrapper {
  border-radius: 16px;
  border: none !important;
  font-family: 'Inter' !important;
}

.ag-center-cols-container {
  font-family: 'Inter';
}

.ag-body-viewport {
  background-color: var(--color-bg-secondary) !important;
}

.ag-row {
  border: none;
  height: 60px !important;
  background-color: var(--color-bg-secondary) !important;
  color: var(--color-text-default) !important;
}

.ag-row-odd {
  background-color: var(--color-bg-secondary) !important;
}

.ag-cell {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600 !important;
  font-family: 'Red Hat Mono' !important;
  color: var(--color-text-default) !important;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-default) !important;
}

.ag-overlay-no-rows-center {
  color: var(--color-text-default) !important;
}

.ag-header,
.ag-header-row {
  height: 80px !important;
  min-height: 80px !important;
  background-color: var(--color-bg-secondary) !important;
  border: none;
}

.ag-header-container .ag-header-cell:nth-of-type(2) {
  margin-left: 6px;
}

.ag-header-cell-text {
  font-size: 14px;
  font-family: Red Hat Text;
  color: var(--color-text-default) !important;
}

.ag-tooltip {
  font-family: 'Red Hat Mono' !important;
  color: var(--color-text-default) !important;
  border: 1px solid var(--color-text-default) !important;
  border-radius: 8px;
}
