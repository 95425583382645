@import './colors.scss';
@import './buttons.scss';
@import './text.scss';
@import './fonts.scss';
@import './common.scss';
@import './layouts.scss';
@import './components.scss';
@import './ag-grid.scss';
body,
html {
  font-size: 16px !important; // TODO rm semantic-ui
}

// TODO rm semantic-ui
html,
.ui,
h1,
h2,
h3,
h4,
h5,
h6,
textarea,
view,
div {
  box-sizing: border-box;
  font-family: 'Red Hat Text', 'Red Hat Mono', Arial, PingFangSC-Regular,
    'Microsoft YaHei', sans-serif !important;
}

// diable input auto increment
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .main-app {
  }
}

.loader-dot1,
.loader-dot2,
.loader-dot3 {
  width: 0.2px;
  height: 0.2px;
  border: double;
  border-radius: 50%;
  margin: 2px;
}

.loader-dot1 {
  animation: jump 1.6s -0.32s linear infinite;
}

.loader-dot2 {
  animation: jump 1.6s -0.16s linear infinite;
}

.loader-dot3 {
  animation: jump 1.6s linear infinite;
}

.font-red-hat-mono {
  font-family: 'Red Hat Mono' !important;
}

@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}
