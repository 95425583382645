.gradient-text {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: var(--gradient-button-primary);
    color: transparent;
}

.gradient-button {
    background-image: var(--gradient-button-secondary);
}

.migration-gradient-button {
    height: 53px;
    background-image: var(--migration-gradient-button-primary);
}