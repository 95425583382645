.manta-blue {
    color: $blue-primary-color;
}

.manta-prime-blue {
    color: $blue-primary-color;
}

.manta-green {
    color: $green-primary-color;
}

.manta-gray {
    color: $gray-light-color;
}

.manta-dark-gray {
    color: $gray-dark-color;
}

.text-blue-thirdry {
    color: $blue-thirdry-color;
}

.link-text {
    color: $blue-primary-color !important;
    text-decoration: underline;
}

.unselectable-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tighter-word-space {
  word-spacing: -1px;  
}