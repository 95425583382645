$body-bgcolor: #f2f3f7;
$gray-primary-color: #22242a;
$gray-light-color: #7e7e7e;
$gray-dark-color: #7e7e7e;
$gray-primary-bgcolor: #b2aeb5;
$blue-primary-color: #4caaac;
$blue-secondary-color: #2c78a6;
$blue-thirdry-color: #5eb1ff;
$green-primary-color: #104ea1;
$blue-primary-bgcolor: linear-gradient(to right, #26c8bf, #1fa2f9);
$blue-secondary-bgcolor: #00afa5;
$blue-primary-hover-bgcolor: linear-gradient(to right, #1fa2f9, #26c8bf);
$error-primary-bgcolor: linear-gradient(to right, #ec134b, #e4f91f);
$blue-light-bgcolor: linear-gradient(180deg, #59b9ae 0%, #398ba8 100%);
$gray-secondary-bgcolor: linear-gradient(180deg, #f5f7ff 0%, #e2e7fc 100%);
$hover-color: linear-gradient(
  0deg,
  rgba(255, 255, 255, 0.1665) 0%,
  rgba(255, 255, 255, 0.45) 100%
); ;
